<script>
import NakedDropdown from '@components/naked-dropdown/naked-dropdown'
import BackButton from '@components/back-button/back-button'
import { getYears } from '@utils/getYears'
import { pick } from 'lodash'
import {
  reclaimsOverviewComputed,
  reclaimsOverviewMethods,
} from '@state/helpers/reclaimsOverview'
import { getSavedStateSession } from '@utils/storage'

export default {
  components: {
    NakedDropdown,
    BackButton,
  },
  props: {
    title: { type: String, default: () => '' },
    onChange: { type: Function, default: () => {} },
    defaultValue: { type: Number, default: () => {} },
    tabSelected: { type: Number, default: null },
    enterpriseId: { type: String, default: () => '' },
  },
  data: () => {
    return {
      options: {
        data: getYears(),
      },
      isReadOnlyUser: getSavedStateSession('isReadOnlyUser'),
    }
  },
  computed: {
    ...pick(reclaimsOverviewComputed, ['currentInvoice']),
  },
  methods: {
    ...pick(reclaimsOverviewMethods, ['getInvoicePdf']),

    onClickAddExpense() {
      this.$store.dispatch('expenseModal/initializeExpense')
      this.$amplitude.getInstance().logEvent('ADD_EXPENSE', {
        enterpriseId: this.enterpriseId,
      })
    },

    onDownloadPdf() {
      this.getInvoicePdf({
        pdfName: this.currentInvoice.documentFileName,
        invoiceId: this.currentInvoice.id,
        isDownload: true,
      })
    },
  },
}
</script>

<template>
  <div :class="$style.subheader">
    <div :class="$style.flexRow">
      <div v-if="this.$route.path.match(/invoice/)" @click="$router.go(-1)">
        <BackButton />
      </div>
      <h1>{{ title }}</h1>
      <v-btn
        v-if="this.$route.path.match(/overview/) && !isReadOnlyUser"
        class="btn ondark-btn"
        :class="$style.button"
        @click="onClickAddExpense"
        >+ {{ $t('ADD_EXPENSE') }}</v-btn
      >
    </div>
    <div
      v-if="this.$route.path.match(/overview/) || tabSelected === 1"
      :class="$style.nakedSelector"
    >
      <NakedDropdown :id="'customNakedDropdown'" :theme="'light'"
      :label="$t('PERIOD')" align: :items="options.data"
      :default-value="defaultValue" dark @change="onChange" />
    </div>

    <div
      v-if="
        this.$route.path.match(/invoice/) &&
        currentInvoice &&
        currentInvoice.id === $route.params.id
      "
      :class="$style.downloadIcon"
      @click.stop="onDownloadPdf"
    ></div>
  </div>
</template>

<style module lang="scss">
@import '@design';
.subheader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 150px;
  padding: 10px 40px;
  margin: -20px -40px 0;
  line-height: 100px;
  background: $dark;

  h1 {
    display: inline;
    font-size: 32px;
    color: white;
  }

  .nakedSelector {
    margin-top: -12px;
    margin-left: auto;
  }

  .flexRow {
    display: flex;
    align-items: center;
  }

  .button {
    margin: 0 0 0 25px;
  }

  .dropdown {
    margin-left: auto;
  }

  .downloadIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    margin: 20px 5px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg width='45' height='45' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='evenodd'%3E%3Ccircle fill='%2300DBB4' cx='22.5' cy='22.5' r='22.5'/%3E%3Cpath d='M33 32.125a.875.875 0 01-.875.875h-19.25a.875.875 0 010-1.75h19.25c.483 0 .875.392.875.875zm-16.625-12.25L22.5 27.75l6.125-7.875h-5.25v-7a.874.874 0 10-1.75 0v7h-5.25z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
  }
}
</style>
