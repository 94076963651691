<script>
import Layout from '@layouts/main/main'

export default {
  components: { Layout },
}
</script>

<template>
  <Layout>
    <div class="content">
      <slot />
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@design';
.content {
  margin: 20px 40px;
}
</style>
