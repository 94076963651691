import { mapState, mapActions, mapMutations } from 'vuex'

export const reclaimsOverviewMethods = mapActions('reclaimsOverview', [
  'getReclaims',
  'getRefunds',
  'setReclaimsPage',
  'setInvoicesPage',
  'setReclaimsSort',
  'setTabSelected',
  'getInvoices',
  'getInvoiceById',
  'setCurrentInvoice',
  'getInvoicePdf',
  'resetInvoicePdf',
])

export const reclaimsOverviewComputed = {
  ...mapState('reclaimsOverview', {
    tabSelected: (state) => state.tabSelected,
    reclaims: (state) => state.reclaims,
    refunds: (state) => state.refunds,
    currentInvoice: (state) => state.currentInvoice,
    invoices: (state) => state.invoices,
    getReclaimsInProgress: (state) => state.getReclaimsInProgress,
    getRefundsInProgress: (state) => state.getRefundsInProgress,
    getReclaimsError: (state) => state.getReclaimsError,
    reclaimsPagination: (state) => state.reclaimsPagination,
    invoicesPagination: (state) => state.invoicesPagination,
    reclaimsSort: (state) => state.reclaimsSort,
    invoicePdf: (state) => state.invoicePdf,
  }),
}

export const reclaimsOverviewMutations = mapMutations('reclaim', [
  'SET_CURRENT_RECLAIM',
])
